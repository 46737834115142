<template>
  <div>
    <modal
      v-if="show_modal"
      :label="`${editing ? 'Edit Practice Area' : 'Add Practice Area'}`"
      :submit_btn_text="'Save'"
      @submit="savePracticeArea($event)"
      @close="closeModal"
      blocking
      medium
      locked
    >
      <template v-slot:content>
        <AddEditPracticeArea v-model="item_to_edit" />
      </template>
    </modal>

    <div>
      <div data-cy="header-admin-practice_areas">
        <h1
          class="header-text pb-3"
          ref="headerPracticeAreas"
          aria-labelledby="header for edit practice area page"
          data-cy="header-admin-reports-pairings"
          autofocus
        >
          Practice Areas
        </h1>
      </div>
    </div>
    <div class="box-style">
      <div class="flex">
        <div class="flex-auto">
          <label class="mb-2" for="student-textbox-filter">
            Search
          </label>
          <input
            id="practice_area_filter"
            type="text"
            v-model="filterText"
            class="border-steel-gray rounded h-8 w-2/3 ml-2"
          />
        </div>
        <div class="flex-auto text-right">
          <Button class="" text="Add New" @click="show_modal = true" />
        </div>
      </div>
      <div v-if="isLoading" class="profile_box">
        <LoadingSpinner />
      </div>
      <div v-else class="profile_box">
        <div>
          <dynamic-table
            :records="this.practice_areas"
            :fields="tableConfig"
            no-data-msg="No Practice Areas to display"
            striped
            hover
          >
            <template #cell(display)="{ item }">
              <div
                class="ml-5 w-5 rounded-full text-center"
                :class="{
                  'bg-alert-success': item.display,
                }"
              >
                <font-awesome-icon v-if="item.display" icon="check" />
                <font-awesome-icon v-else icon="ban" />
              </div>
            </template>
            <template #cell(edit)="{ item }">
              <Button text="Edit" size="small" @click="openToEdit(item)" />
            </template>
            <template #cell(hide)="{ item }">
              <Button
                v-if="item.display"
                text="Hide"
                size="small"
                @click="hidePracticeArea(item)"
              />
              <Button
                v-else
                text="Show"
                size="small"
                @click="showPracticeArea(item)"
              />
            </template>
          </dynamic-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import Button from "@/components/reusable-components/base-level-components/Button";
import AddEditPracticeArea from "@/views/admin/sys-administration/practice_areas/AddEditPracticeArea";
import Modal from "@/components/modal/Modal";
import { del, get, post, put } from "@/composables/httpUtil";
import { emitAlert } from "@/composables/alerts";

export default {
  name: "AdminPracticeAreas",
  components: {
    LoadingSpinner,
    DynamicTable,
    Button,
    AddEditPracticeArea,
    Modal,
  },
  data() {
    return {
      isLoading: true,
      practice_areas: [],
      filterText: "",
      show_modal: false,
      editing: false,
      item_to_edit: {
        practice_area_id: "",
        description: "",
      },
      tableConfig: [
        {
          display: "Practice Area",
          name: "description",
          sortable: false,
          class: "",
        },
        {
          display: "Displayed",
          name: "display",
          sortable: false,
          class: "",
        },
        {
          display: "Edit",
          name: "edit",
          sortable: false,
          class: "",
        },
        {
          display: "Hide / Show",
          name: "hide",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.getPracticeAreas();
  },
  methods: {
    getPracticeAreas() {
      let url = `admin/practice_areas`;
      url += this.filterText !== "" ? `?name=${this.filterText}` : "";
      get(url).then((data) => {
        this.practice_areas = data;
        this.practice_areas.sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
        this.isLoading = false;
      });
    },
    openToEdit(item) {
      this.item_to_edit = item;
      this.editing = true;
      this.show_modal = true;
    },
    savePracticeArea() {
      if (this.item_to_edit.description) {
        if (this.item_to_edit.practice_area_id) {
          put(
            "admin/practice_areas/" + this.item_to_edit.practice_area_id,
            this.item_to_edit
          ).then((data) => {
            emitAlert("success", `${data.description} Updated`, {
              timeout: 5000,
            });
          });
        } else {
          delete this.item_to_edit.practice_area_id;
          post("admin/practice_areas/", this.item_to_edit).then((data) => {
            this.practice_areas.push(data);
            this.practice_areas.sort((a, b) => {
              return a.description.localeCompare(b.description);
            });
            emitAlert("success", `${data.description} Added`, {
              timeout: 5000,
            });
          });
        }
        this.editing = false;
        this.show_modal = false;
        this.item_to_edit = {
          practice_area_id: "",
          description: "",
        };
      } else {
        emitAlert("error", `Description Needed`, {
          timeout: 5000,

        });
      }
    },
    hidePracticeArea(item) {
      item.display = false;
      del("admin/practice_areas/" + item.practice_area_id).then((data) => {
        emitAlert("success", `${data.description} Hidden`, {
          timeout: 5000,
        });
      });
    },
    showPracticeArea(item) {
      item.display = true;
      put("admin/practice_areas/" + item.practice_area_id, item).then(
        (data) => {
          emitAlert("success", `${data.description} Shown`, {
            timeout: 5000,
          });
        }
      );
    },
    closeModal() {
      this.show_modal = false;
      this.editing = false;
      this.item_to_edit = {
        practice_area_id: "",
        description: "",
      };
    },
  },
  watch: {
    filterText: {
      handler() {
        this.getPracticeAreas();
      },
    },
  },
};
</script>

<style scoped></style>
