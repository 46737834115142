<template>
  <div>
    <input
      id="practice_area_description"
      type="text"
      v-model="item.description"
      class="border-steel-gray rounded h-8 w-full"
    />
  </div>
</template>

<script>
export default {
  name: "AddEditPracticeArea",
  props: {
    modelValue: { type: Object, required: true },
  },
  computed: {
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style scoped></style>
